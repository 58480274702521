import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/core/base.component';
import { TranslateListService } from 'src/app/core/translate-list.service';
import {
  Anstellungsverhaeltnis,
  DauerdesVertragsverhaeltnissesTyp,
  Personendaten,
} from 'src/app/shared/model/personendaten';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { NavButtonBarService } from 'src/app/shared/services/nav-button-bar.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { ValuesService } from 'src/app/shared/services/values.service';

@Component({
  selector: 'app-beschaeftigung-summary',
  templateUrl: './beschaeftigung-summary.component.html',
  styleUrls: ['../../summary.component.scss', './beschaeftigung-summary.component.scss']
})
export class BeschaeftigungSummaryComponent extends BaseComponent implements OnInit {
  personOneAnstellungsverhaeltnis: Anstellungsverhaeltnis;
  personTwoAnstellungsverhaeltnis: Anstellungsverhaeltnis;
  personOneDauerdesVertragsverhaeltnisses: string;
  personTwoDauerdesVertragsverhaeltnisses: string;
  personOneBranch: string;
  personTwoBranch: string;
  isPersonOneRetired: boolean;
  isPersonTwoRetired: boolean;
  constructor(
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    dateService: DateService,
    private valuesService: ValuesService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService, true);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initLocalProperties();
  }

  initLocalProperties() {
    this.getAnstellungsverhaeltnisFirstPerson();
    this.getAnstellungsverhaeltnisSecondPerson();
    this.getBranchFirstPerson();
    this.getBranchSecondPerson();
    this.personOneDauerdesVertragsverhaeltnisses = this.getDauerdesVertragsverhaeltnisses(this.personendaten);
    this.personTwoDauerdesVertragsverhaeltnisses = this.getDauerdesVertragsverhaeltnisses(this.personendatenSecond);
    this.isPersonOneRetired = this.isPersonRetired(this.personendaten);
    this.isPersonTwoRetired = this.isPersonRetired(this.personendatenSecond);
  }

  isPersonRetired(personendaten: Personendaten) {
    return personendaten?.anstellungsverhaeltnis === Anstellungsverhaeltnis.Pensioner || personendaten?.anstellungsverhaeltnis === Anstellungsverhaeltnis.RetireePerson
      || personendaten?.anstellungsverhaeltnis === Anstellungsverhaeltnis.GermaArmedForcesRetired;
  }

  getDauerdesVertragsverhaeltnisses(personendaten: Personendaten) {
    switch (personendaten?.dauerdesVertragsverhaeltnissesTyp) {
      case DauerdesVertragsverhaeltnissesTyp.Befristet:
        return 'befristet';
      case DauerdesVertragsverhaeltnissesTyp.Unbefristet:
        return 'unbefristet';
      case DauerdesVertragsverhaeltnissesTyp.Unkuendbar:
        return 'unkündbar';
      case DauerdesVertragsverhaeltnissesTyp.Probezeit:
        return 'in Probezeit';
    }
  }

  getBranchFirstPerson() {
    this.valuesService.loadBranchen().subscribe(branchList => {
      branchList.forEach(branch => {
        if (this.personendaten?.branche === branch.value) {
          this.personOneBranch = branch.label;
        }
      });
    });
  }

  getBranchSecondPerson() {
    this.valuesService.loadBranchen().subscribe(branchList => {
      branchList.forEach(branch => {
        if (this.personendatenSecond?.branche === branch.value) {
          this.personTwoBranch = branch.label;
        }
      });
    });
  }

  getAnstellungsverhaeltnisFirstPerson() {
    this.valuesService.loadEmployments().subscribe(employmentList => {
      employmentList.forEach(employment => {
        if (this.personendaten?.anstellungsverhaeltnis === employment.value) {
          this.personOneAnstellungsverhaeltnis = employment.label;
        }
      });
    });
  }

  getAnstellungsverhaeltnisSecondPerson() {
    this.valuesService.loadEmployments().subscribe(employmentList => {
      employmentList.forEach(employment => {
        if (this.personendatenSecond?.anstellungsverhaeltnis === employment.value) {
          this.personTwoAnstellungsverhaeltnis = employment.label;
        }
      });
    });
  }
}
