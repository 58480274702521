import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/core/base.component';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { Immobilie } from 'src/app/shared/model/immobilie';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { NavButtonBarService } from 'src/app/shared/services/nav-button-bar.service';
import { PopupService } from 'src/app/shared/services/popup.service';

@Component({
  selector: 'app-unbebaute-grundstuecke',
  templateUrl: './unbebaute-grundstuecke.component.html',
  styleUrls: ['../../summary.component.scss', './unbebaute-grundstuecke.component.scss']
})
export class UnbebauteGrundstueckeComponent extends BaseComponent implements OnInit {
  grundstuecke: Immobilie[];
  grundstueckesecond: Immobilie[];
  constructor(
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    dateService: DateService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService, true);
  }

  ngOnInit() {
    super.ngOnInit();
    this.grundstuecke = this.haushaltsrechnung?.vermoegen?.unbebautesGrundstueck;
    this.grundstueckesecond = this.haushaltsrechnungSecond?.vermoegen?.unbebautesGrundstueck;
  }
}
