<div class="toggle-container row">
    <div class="col-sm-12 col-lg-12">
        <h4 *ngIf="immobilien.length > 0">{{'summary.financing-check.first-borrower.prefix' | translate}}</h4>
        <div *ngFor="let immobilie of immobilien; index as i">
            <hr *ngIf="i !== 0">
            <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.leased-out-real-estate.adress.prefix' | translate}}
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3">
                    {{immobilie.strasse}} {{immobilie.hausnummer}} <br />
                    {{immobilie.plz}} {{immobilie.ort}}
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.leased-out-real-estate.worth.prefix' | translate}}
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3">
                    {{immobilie.wert | currency: 'EUR'}}
                </div>
            </div>
        </div>
        <h4 *ngIf="immobiliensecond?.length > 0">{{'summary.financing-check.second-borrower.prefix' | translate}}</h4>
        <div *ngFor="let immobilie of immobiliensecond; index as i">
            <hr *ngIf="i !== 0">
            <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.leased-out-real-estate.adress.prefix' | translate}}
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3">
                    {{immobilie.strasse}} {{immobilie.hausnummer}} <br />
                    {{immobilie.plz}} {{immobilie.ort}}
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.leased-out-real-estate.worth.prefix' | translate}}
                </div>
                <div class="col-sm-6 col-md-6 col-lg-3">
                    {{immobilie.wert | currency: 'EUR'}}
                </div>
            </div>
        </div>
    </div>
</div>