<div class="toggle-container row">
    <div class="col-sm-12 col-lg-12">
        <h4 *ngIf="baufinanzierung.length > 0">{{'summary.financing-check.first-borrower.prefix' | translate}}</h4>
        <div *ngFor="let baufi of baufinanzierung; index as i">
            <hr *ngIf="i !== 0">
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.building.name-of-bank.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{baufi.zahlungsempfaenger}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.building.sum.prefix'| translate}} </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{baufi.zahlungssumme | currency: 'EUR'}}
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.building.monthly-rate.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{ baufi.monatlicheKosten | currency: 'EUR'}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.building.current-residual-debt.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{ baufi.aktuelleRestschuld | currency: 'EUR'}}
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.building.end-of-fixed-interest-period.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{ baufi.zinsbindungsEnde | date: 'mediumDate'}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.building.end-of-repayment.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{ baufi.tilgungsEnde | date: 'mediumDate'}}
                </div>
            </div>
        </div>
        <h4 *ngIf="baufinanzierungsecond?.length > 0">{{'summary.financing-check.second-borrower.prefix' | translate}}</h4>
        <div *ngFor="let baufi of baufinanzierungsecond; index as i">
            <hr *ngIf="i !== 0">
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.building.name-of-bank.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{baufi.zahlungsempfaenger}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.building.sum.prefix'| translate}} </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{baufi.zahlungssumme | currency: 'EUR'}}
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.building.monthly-rate.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{ baufi.monatlicheKosten | currency: 'EUR'}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.building.current-residual-debt.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{ baufi.aktuelleRestschuld | currency: 'EUR'}}
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.building.end-of-fixed-interest-period.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{ baufi.zinsbindungsEnde | date: 'mediumDate'}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.building.end-of-repayment.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{ baufi.tilgungsEnde | date: 'mediumDate'}}
                </div>
            </div>
        </div>
    </div>