<div class="toggle-container row">
    <div class="col-sm-12 col-lg-12">
        <h4>{{'summary.financing-check.first-borrower.prefix' | translate}}</h4>
        <div class="row">
            <div class="col-sm-12 col-md-6 col-lg-6 ">
                {{'summary.personal-data.personal-data.employment-details.employment-relationship.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{personOneAnstellungsverhaeltnis}}
            </div>
        </div>
        <div class="row" *ngIf="personendaten.dauerdesVertragsverhaeltnissesTyp">
            <div class="col-sm-12 col-md-6 col-lg-6 ">
                {{'summary.personal-data.personal-data.employment-details.duration-of-contractual-relationship.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{personOneDauerdesVertragsverhaeltnisses}}
            </div>
        </div>
        <div class="row" *ngIf="personendaten.beschaeftigtSeit">
            <div class="col-sm-12 col-md-6 col-lg-6 ">
                {{'summary.personal-data.personal-data.employment-details.employed-since.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{personendaten.beschaeftigtSeit | date: 'mediumDate'}}
            </div>
        </div>
        <div class="row" *ngIf="personendaten.beschaeftigtBis">
            <div class="col-sm-12 col-md-6 col-lg-6 ">
                {{'summary.personal-data.personal-data.employment-details.employed-until.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{personendaten.beschaeftigtBis | date: 'mediumDate'}}
            </div>
        </div>
        <div class="row" *ngIf="personendaten.branche">
            <div class="col-sm-12 col-md-6 col-lg-6 ">
                {{'summary.personal-data.personal-data.employment-details.branch.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{personOneBranch}}
            </div>
        </div>
        <div class="row" *ngIf="personendaten.arbeitgeber">
            <div class="col-sm-12 col-md-6 col-lg-6 ">
                {{'summary.personal-data.personal-data.employment-details.employer.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{personendaten.arbeitgeber}}
            </div>
        </div>
        <div class="row" *ngIf="personendaten.beruf">
            <div class="col-sm-12 col-md-6 col-lg-6 ">
                {{'summary.personal-data.personal-data.employment-details.profession.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{personendaten.beruf}}
            </div>
        </div>
        <div class="row" *ngIf="rentenInformation.regulaererRentenEintritt">
            <div class="col-sm-12 col-md-6 col-lg-6 ">
                {{'summary.personal-data.personal-data.employment-details.retirement-age.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{rentenInformation.regulaererRentenEintritt | date: 'mediumDate'}}
            </div>
        </div>
        <div class="row" *ngIf="rentenInformation.geplanterRentenEintritt">
            <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="isPersonOneRetired">
                {{'summary.personal-data.personal-data.employment-details.retirement-age-since.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="!isPersonOneRetired">
                {{'summary.personal-data.personal-data.employment-details.retirement-age-planned.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{rentenInformation.geplanterRentenEintritt | date: 'mediumDate'}}
            </div>
        </div>

        <div *ngIf="antrag.anzahlAntragnehmer === 2">
            <hr>
            <h4>{{'summary.financing-check.second-borrower.prefix' | translate}}</h4>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6 ">
                    {{'summary.personal-data.personal-data.employment-details.employment-relationship.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6">
                    {{personTwoAnstellungsverhaeltnis}}
                </div>
            </div>
        </div>
        <div class="row" *ngIf="personendatenSecond?.dauerdesVertragsverhaeltnissesTyp">
            <div class="col-sm-12 col-md-6 col-lg-6 ">
                {{'summary.personal-data.personal-data.employment-details.duration-of-contractual-relationship.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{personTwoDauerdesVertragsverhaeltnisses}}
            </div>
        </div>
        <div class="row" *ngIf="personendatenSecond?.beschaeftigtSeit">
            <div class="col-sm-12 col-md-6 col-lg-6 ">
                {{'summary.personal-data.personal-data.employment-details.employed-since.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{personendatenSecond?.beschaeftigtSeit | date: 'mediumDate'}}
            </div>
        </div>
        <div class="row" *ngIf="personendatenSecond?.beschaeftigtBis">
            <div class="col-sm-12 col-md-6 col-lg-6 ">
                {{'summary.personal-data.personal-data.employment-details.employed-until.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{personendatenSecond?.beschaeftigtBis | date: 'mediumDate'}}
            </div>
        </div>
        <div class="row" *ngIf="personendatenSecond?.branche">
            <div class="col-sm-12 col-md-6 col-lg-6 ">
                {{'summary.personal-data.personal-data.employment-details.branch.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{personTwoBranch}}
            </div>
        </div>
        <div class="row" *ngIf="personendatenSecond?.arbeitgeber">
            <div class="col-sm-12 col-md-6 col-lg-6 ">
                {{'summary.personal-data.personal-data.employment-details.employer.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{personendatenSecond?.arbeitgeber}}
            </div>
        </div>
        <div class="row" *ngIf="personendatenSecond?.beruf">
            <div class="col-sm-12 col-md-6 col-lg-6 ">
                {{'summary.personal-data.personal-data.employment-details.profession.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{personendatenSecond?.beruf}}
            </div>
        </div>
        <div class="row" *ngIf="rentenInformationSecond?.regulaererRentenEintritt">
            <div class="col-sm-12 col-md-6 col-lg-6 ">
                {{'summary.personal-data.personal-data.employment-details.retirement-age.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{rentenInformationSecond?.regulaererRentenEintritt | date: 'mediumDate'}}
            </div>
        </div>
        <div class="row" *ngIf="rentenInformationSecond?.geplanterRentenEintritt">
            <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="isPersonTwoRetired">
                {{'summary.personal-data.personal-data.employment-details.retirement-age-since.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6" *ngIf="!isPersonTwoRetired">
                {{'summary.personal-data.personal-data.employment-details.retirement-age-planned.prefix' | translate}}
            </div>
            <div class="col-sm-12 col-md-6 col-lg-6">
                {{rentenInformationSecond?.geplanterRentenEintritt | date: 'mediumDate'}}
            </div>
        </div>
    </div>
</div>