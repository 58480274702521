export const environment = {
  production: true,
  baseUrl: 'https://oap-test-1.widas.de',
  baseUrlCidaas: 'https://oap-test.cidaas.eu',
  redirectDBHomePage: 'https://www.deutsche-bank.de/pk/Immobilie/immobilie-und-finanzierung-im-ueberblick.html',
  legalInformationUrl: 'https://www.deutsche-bank.de/datenschutz',
  consentDeclarationUrl: 'https://baufilive.postbank.de/pdf/ErklaerungBHW.pdf',
  consentAgbUrl: 'https://www.bhw.de/allgemeine_geschaeftsbedingungen.html?pfb_toggle=8740-8747',
  emailSaveDB: 'bhw-baufilive@widas.de',
  emailSaveBHW: 'bhw-baufilive@widas.de',
  emailContactDB: 'bhw-baufilive@widas.de',
  vgeNumberDB: '08882074952',
  c1: '53616c7465645f5fd2f0281bdab3ba0051d2d8e57b4ea50ec3df47ca58d6e07d92568ccde650d23707b369147d7bb8a53db8c12e1dca66e584d76741ca13edba',
  c2: '53616c7465645f5f1f8982af81c4556e46e5b494904045f870c70ab69a3fbeabfc3f787f5115ac879a022ae104e2920e6137b08c44d4df8f8e29aab56f3680fc',
  adressValidationUrl: 'https://oap-test-1.widas.de/validate/address?street={applicantStreet}&hno={applicantHno}&zip={applicantZip}&city={applicantCity}',
};
