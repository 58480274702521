<form [formGroup]="formValidation">
  <div class="ui-control-label ">
    {{ 'personal-fortune-second.assets.label' | translate: correspondenceSecond }}
  </div>
  <div class="row">
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input autocomplete="off" type="number" [(model)]="kontoguthaben" label="{{
                    'personal-fortune.account-balance.placeholder' | translate
                }}">
      </db-input>
    </div>
    <div class="col-sm-12 col-md-6 ui-control">
      <db-input autocomplete="off" type="number" [(model)]="kurswert" label="{{ 'personal-fortune.bonds.placeholder' | translate }}">
      </db-input>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12 col-md-4 ui-control">
      <db-input autocomplete="off" type="number" [(model)]="bausparguthaben" label="{{
                    'personal-fortune.building-loan-contracts.placeholder'
                        | translate
                }}">
      </db-input>
    </div>
    <div class="col-sm-12 col-md-4 ui-control">
      <db-input autocomplete="off" type="number" [(model)]="rueckkaufswertLebensversicherungen" label="{{
                    'personal-fortune.surrender-value-life-insurance-policies.placeholder'
                        | translate
                }}">
      </db-input>
    </div>

  </div>

  <div class="{{ checkToggleClass('eigeneImmobilie') }}">
    <db-intro-preview>
      <db-toggleable [label]="'personal-fortune.own-real-estate.label' | translate" [open]="toggleOpenArray[0]" iconPosition="right"
        (openChange)="toggleClickAccordion(0, 'eigeneImmobilie')">
        <div class="toggle-container" *ngFor="
                        let credit of getProperties('eigeneImmobilie').controls;
                        let i = index
                    ">
          <div [formGroup]="credit">
            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
                <db-input type="number" autocomplete="off" formControlName="wert" [(model)]="eigeneImmobilie[i].wert" [label]="
                                        'personal-fortune.own-real-estate.worth.placeholder'
                                            | translate
                                    ">
                </db-input>
                <db-validation-errors for="wert" [messages]="errors.wert"></db-validation-errors>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
                <db-input type="text" autocomplete="off" formControlName="plz" [(model)]="eigeneImmobilie[i].plz" [label]="
                                        'personal-fortune.own-real-estate.zip-code.placeholder'
                                            | translate
                                    ">
                </db-input>
                <db-validation-errors for="plz" [messages]="errors.plz"></db-validation-errors>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
                <db-input type="text" [(model)]="eigeneImmobilie[i].ort" formControlName="ort" autocomplete="off" [label]="
                                        'personal-fortune.own-real-estate.place.placeholder'
                                            | translate
                                    ">
                </db-input>
                <db-validation-errors for="ort" [messages]="errors.ort"></db-validation-errors>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
                <db-input type="text" [(model)]="eigeneImmobilie[i].strasse" formControlName="strasse" autocomplete="off" [label]="
                                        'personal-fortune.own-real-estate.street.placeholder'
                                            | translate
                                    ">
                </db-input>
                <db-validation-errors for="strasse" [messages]="errors.strasse"></db-validation-errors>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
                <db-input [(model)]="eigeneImmobilie[i].hausnummer" formControlName="hausnummer" autocomplete="off" [label]="
                                        'personal-fortune.own-real-estate.house-number.placeholder'
                                            | translate
                                    ">
                </db-input>
                <db-validation-errors for="hausnummer" [messages]="errors.hausnummer"></db-validation-errors>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
                <db-button design="primary" (click)="
                                        removeProperty('eigeneImmobilie', i)
                                    ">{{ i + 1 }}.
                  {{
                                        'personal-fortune.own-real-estate.button.remove'
                                            | translate
                                    }}
                </db-button>
              </div>
            </div>
          </div>
        </div>

        <div>
          <db-button design="primary" (click)="addProperty('eigeneImmobilie')">
            {{
                            'personal-fortune.own-real-estate.button.add'
                                | translate
                        }}
          </db-button>
          &nbsp;
        </div>
      </db-toggleable>
    </db-intro-preview>
  </div>

  <div class="{{ checkToggleClass('vermieteteImmobilie') }}">
    <db-intro-preview>
      <db-toggleable [label]="
                    'personal-fortune.leased-out-real-estate.label' | translate
                " [open]="toggleOpenArray[1]" iconPosition="right" (openChange)="toggleClickAccordion(1, 'vermieteteImmobilie')">
        <div class="toggle-container" *ngFor="
                        let rented of getProperties('vermieteteImmobilie')
                            .controls;
                        let j = index
                    ">
          <div [formGroup]="rented">
            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
                <db-input type="number" autocomplete="off" formControlName="wert" [(model)]="vermieteteImmobilie[j].wert" [label]="
                                        'personal-fortune.own-real-estate.worth.placeholder'
                                            | translate
                                    ">
                </db-input>
                <db-validation-errors for="wert" [messages]="errors.wert"></db-validation-errors>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
                <db-input type="text" autocomplete="off" formControlName="plz" [(model)]="vermieteteImmobilie[j].plz" [label]="
                                        'personal-fortune.own-real-estate.zip-code.placeholder'
                                            | translate
                                    ">
                </db-input>
                <db-validation-errors for="plz" [messages]="errors.plz"></db-validation-errors>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
                <db-input type="text" [(model)]="vermieteteImmobilie[j].ort" formControlName="ort" autocomplete="off" [label]="
                                        'personal-fortune.own-real-estate.place.placeholder'
                                            | translate
                                    ">
                </db-input>
                <db-validation-errors for="ort" [messages]="errors.ort"></db-validation-errors>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
                <db-input type="text" [(model)]="vermieteteImmobilie[j].strasse" formControlName="strasse" autocomplete="off" [label]="
                                        'personal-fortune.own-real-estate.street.placeholder'
                                            | translate
                                    ">
                </db-input>
                <db-validation-errors for="strasse" [messages]="errors.strasse"></db-validation-errors>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
                <db-input [(model)]="
                                        vermieteteImmobilie[j].hausnummer
                                    " formControlName="hausnummer" autocomplete="off" [label]="
                                        'personal-fortune.own-real-estate.house-number.placeholder'
                                            | translate
                                    ">
                </db-input>
                <db-validation-errors for="hausnummer" [messages]="errors.hausnummer"></db-validation-errors>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
                <db-button design="primary" (click)="
                                        removeProperty('vermieteteImmobilie', j)
                                    ">{{ j + 1 }}.
                  {{
                                        'personal-fortune.own-real-estate.button.remove'
                                            | translate
                                    }}
                </db-button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <db-button design="primary" (click)="addProperty('vermieteteImmobilie')">
            {{
                            'personal-fortune.leased-out-real-estate.button.add'
                                | translate
                        }}
          </db-button>
          &nbsp;
        </div>
      </db-toggleable>
    </db-intro-preview>
  </div>

  <div class="{{ checkToggleClass('unbebautesGrundstueck') }}">
    <db-intro-preview>
      <db-toggleable [label]="'personal-fortune.undeveloped-plots.label' | translate" [open]="toggleOpenArray[3]" iconPosition="right"
        (openChange)="toggleClickAccordion(3, 'unbebautesGrundstueck')">
        <div class="toggle-container" *ngFor="
                        let undevelope of getProperties('unbebautesGrundstueck')
                            .controls;
                        let k = index
                    ">
          <div [formGroup]="undevelope">
            <div class="row">
              <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
                <db-input type="number" autocomplete="off" formControlName="wert" [(model)]="unbebautesGrundstueck[k].wert" [label]="
                                        'personal-fortune.own-real-estate.worth.placeholder'
                                            | translate
                                    ">
                </db-input>
                <db-validation-errors for="wert" [messages]="errors.wert"></db-validation-errors>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
                <db-input type="text" autocomplete="off" formControlName="plz" [(model)]="unbebautesGrundstueck[k].plz" [label]="
                                        'personal-fortune.own-real-estate.zip-code.placeholder'
                                            | translate
                                    ">
                </db-input>
                <db-validation-errors for="plz" [messages]="errors.plz"></db-validation-errors>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
                <db-input type="text" [(model)]="unbebautesGrundstueck[k].ort" formControlName="ort" autocomplete="off" [label]="
                                        'personal-fortune.own-real-estate.place.placeholder'
                                            | translate
                                    ">
                </db-input>
                <db-validation-errors for="ort" [messages]="errors.ort"></db-validation-errors>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
                <db-input type="text" [(model)]="unbebautesGrundstueck[k].strasse" formControlName="strasse" autocomplete="off" [label]="
                                        'personal-fortune.own-real-estate.street.placeholder'
                                            | translate
                                    ">
                </db-input>
                <db-validation-errors for="strasse" [messages]="errors.strasse">
                </db-validation-errors>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
                <db-input [(model)]="
                                        unbebautesGrundstueck[k].hausnummer
                                    " formControlName="hausnummer" autocomplete="off" [label]="
                                        'personal-fortune.own-real-estate.house-number.placeholder'
                                            | translate
                                    ">
                </db-input>
                <db-validation-errors for="hausnummer" [messages]="errors.hausnummer">
                </db-validation-errors>
              </div>
              <div class="col-sm-12 col-md-6 col-lg-4 ui-control">
                <db-button design="primary" (click)="
                                        removeProperty(
                                            'unbebautesGrundstueck',
                                            k
                                        )
                                    ">{{ k + 1 }}.
                  {{
                                        'personal-fortune.undeveloped-plots.button.remove'
                                            | translate
                                    }}
                </db-button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <db-button design="primary" (click)="addProperty('unbebautesGrundstueck')">
            {{
                            'personal-fortune.undeveloped-plots.button.add'
                                | translate
                        }}
          </db-button>
          &nbsp;
        </div>
      </db-toggleable>
    </db-intro-preview>
  </div>
  <div class="db-validation-errors__message form-validation-error" [ngClass]="{ 'is-visible': formValidation.invalid }">
    {{ 'personal-fortune.property-unfilled.hint' | translate }}
  </div>
</form>
