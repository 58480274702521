import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/core/base.component';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { Personendaten } from 'src/app/shared/model/personendaten';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { NavButtonBarService } from 'src/app/shared/services/nav-button-bar.service';
import { PopupService } from 'src/app/shared/services/popup.service';
import { ValuesService } from 'src/app/shared/services/values.service';

@Component({
  selector: 'app-grunddaten-summary',
  templateUrl: './grunddaten-summary.component.html',
  styleUrls: ['../../summary.component.scss', './grunddaten-summary.component.scss']
})
export class GrunddatenSummaryComponent extends BaseComponent implements OnInit {
  person: Personendaten;
  personOneBirthCountry: string;
  personTwoBirthCountry: string;
  personOneNationality: string;
  personTwoNationality: string;
  personOneZweiteStaatsangehoerigkeitUSA: string;
  personTwoZweiteStaatsangehoerigkeitUSA: string;
  constructor(
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    dateService: DateService,
    private valuesService: ValuesService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService, true);
  }

  ngOnInit() {
    super.ngOnInit();
    this.initLocalProperties();
  }

  initLocalProperties() {
    this.getBirthCountryFirstPerson();
    this.getBirthCountrySecondPerson();
    this.getNationalityFirstPerson();
    this.getNationalitySecondPerson();
    this.personOneZweiteStaatsangehoerigkeitUSA = this.personendaten?.zweiteStaatsangehoerigkeitUSA ? 'Ja' : 'Nein';
    this.personTwoZweiteStaatsangehoerigkeitUSA = this.personendatenSecond?.zweiteStaatsangehoerigkeitUSA ? 'Ja' : 'Nein';
  }

  getBirthCountryFirstPerson() {
    this.valuesService.loadCountries().subscribe(countryList => {
      countryList?.forEach(country => {
        if (this.personendaten?.geburtsland === country.value) {
          this.personOneBirthCountry = country.label;
        }
      });
    });
  }

  getNationalityFirstPerson() {
    this.valuesService.loadNationalities().subscribe(nationalityList => {
      nationalityList?.forEach(nationality => {
        if (this.personendaten?.staatsangehoerigkeit === nationality.value) {
          this.personOneNationality = nationality.label;
        }
      });
    });
  }

  getBirthCountrySecondPerson() {
    this.valuesService.loadCountries().subscribe(countryList => {
      countryList?.forEach(country => {
        if (this.personendatenSecond?.geburtsland === country.value) {
          this.personTwoBirthCountry = country.label;
        }
      });
    });
  }

  getNationalitySecondPerson() {
    this.valuesService.loadNationalities().subscribe(nationalityList => {
      nationalityList?.forEach(nationality => {
        if (this.personendatenSecond?.staatsangehoerigkeit === nationality.value) {
          this.personTwoNationality = nationality.label;
        }
      });
    });
  }

  getNationality(): string {
    return null;
  }
}
