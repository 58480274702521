import { Zahlungsinformation } from './zahlungsinformation';

export class Ausgaben {
  eigenmieteKalt: number;
  eigenmieteNebenkosten: number;
  praemieKrankenversicherung: number;
  unterhaltszahlungen: number;
  sonstAusgabenBetrag: number;
  sonstAusgabenText: string;
  lebensHaltungsKosten: number;
  freiwilligeSparLeistungen: number;
  kfzKosten: number;
  kfzAnzahl: number;
  ratenkredit: Zahlungsinformation[];
  leasing: Zahlungsinformation[];
  baufinanzierung: Zahlungsinformation[];
  baufinanzierungAndereBank: Zahlungsinformation[];
  sonstMonatlicheKosten: Zahlungsinformation[];

  constructor(ausgaben?: Ausgaben) {
    this.eigenmieteKalt = ausgaben ? ausgaben.eigenmieteKalt : null;
    this.eigenmieteNebenkosten = ausgaben ? ausgaben.eigenmieteNebenkosten : null;
    this.praemieKrankenversicherung = ausgaben ? ausgaben.praemieKrankenversicherung : null;
    this.unterhaltszahlungen = ausgaben ? ausgaben.unterhaltszahlungen : null;
    this.sonstAusgabenBetrag = ausgaben ? ausgaben.sonstAusgabenBetrag : null;
    this.sonstAusgabenText = ausgaben ? ausgaben.sonstAusgabenText : null;
    this.lebensHaltungsKosten = ausgaben ? ausgaben.lebensHaltungsKosten : null;
    this.freiwilligeSparLeistungen = ausgaben ? ausgaben.freiwilligeSparLeistungen : null;
    this.ratenkredit = new Array();
    if (ausgaben && ausgaben.ratenkredit && ausgaben.ratenkredit.length > 0) {
      ausgaben.ratenkredit.forEach((rate, index) => {
        this.ratenkredit[index] = new Zahlungsinformation(rate);
      });
    }
    this.leasing = new Array();
    if (ausgaben && ausgaben.leasing && ausgaben.leasing.length > 0) {
      ausgaben.leasing.forEach((leasingElem, index) => {
        this.leasing[index] = new Zahlungsinformation(leasingElem);
      });
    }
    this.baufinanzierung = new Array();
    if (ausgaben && ausgaben.baufinanzierung && ausgaben.baufinanzierung.length > 0) {
      ausgaben.baufinanzierung.forEach((baufinanzierungElem, index) => {
        this.baufinanzierung[index] = new Zahlungsinformation(baufinanzierungElem);
      });
    }
    this.baufinanzierungAndereBank = new Array();
    if (ausgaben && ausgaben.baufinanzierungAndereBank && ausgaben.baufinanzierungAndereBank.length > 0) {
      ausgaben.baufinanzierungAndereBank.forEach((baufinanzierungAndereBankElem, index) => {
        this.baufinanzierungAndereBank[index] = new Zahlungsinformation(baufinanzierungAndereBankElem);
      });
    }
    this.sonstMonatlicheKosten = new Array();
    if (ausgaben && ausgaben.sonstMonatlicheKosten && ausgaben.sonstMonatlicheKosten.length > 0) {
      ausgaben.sonstMonatlicheKosten.forEach((sonstMonatlicheKostenElem, index) => {
        this.sonstMonatlicheKosten[index] = new Zahlungsinformation(sonstMonatlicheKostenElem);
      });
    }
  }
}
