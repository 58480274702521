<div class="toggle-container row">
    <div class="col-sm-12 col-lg-12">
        <h4 *ngIf="leasingContracts.length > 0">{{'summary.financing-check.first-borrower.prefix' | translate}}</h4>
        <div *ngFor="let leasing of leasingContracts; index as i">
            <hr *ngIf="i !== 0">
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.leasing-contracts.lessor.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{leasing.zahlungsempfaenger}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.leasing-contracts.leasing-sum.prefix'| translate}} </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{leasing.zahlungssumme | currency: 'EUR'}}
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.leasing-contracts.monthly-rate.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{ leasing.monatlicheKosten | currency: 'EUR'}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.leasing-contracts.end-of-repayment.prefix' | translate}}
                </div>
                <div class=" col-sm-12 col-md-6 col-lg-3">
                    {{ leasing.tilgungsEnde | date: 'mediumDate'}}
                </div>
            </div>
        </div>
        <h4 *ngIf="leasingContractsSecond?.length > 0">{{'summary.financing-check.second-borrower.prefix' | translate}}</h4>
        <div *ngFor="let leasing of leasingContractsSecond; index as i">
            <hr *ngIf="i !== 0">
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.leasing-contracts.lessor.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{leasing.zahlungsempfaenger}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.leasing-contracts.leasing-sum.prefix'| translate}} </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{leasing.zahlungssumme | currency: 'EUR'}}
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.leasing-contracts.monthly-rate.prefix' | translate}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{ leasing.monatlicheKosten | currency: 'EUR'}}
                </div>
                <div class="col-sm-12 col-md-6 col-lg-3">
                    {{'summary.personal-data.other-expenditure-and-assets.leasing-contracts.end-of-repayment.prefix' | translate}}
                </div>
                <div class=" col-sm-12 col-md-6 col-lg-3">
                    {{ leasing.tilgungsEnde | date: 'mediumDate'}}
                </div>
            </div>
        </div>
    </div>
</div>