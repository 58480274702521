import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from 'src/app/core/base.component';
import { TranslateListService } from 'src/app/core/translate-list.service';
import { Zahlungsinformation } from 'src/app/shared/model/zahlungsinformation';
import { ApplicationDataService } from 'src/app/shared/services/application-data.service';
import { DateService } from 'src/app/shared/services/date.service';
import { HeaderService } from 'src/app/shared/services/header.service';
import { NavButtonBarService } from 'src/app/shared/services/nav-button-bar.service';
import { PopupService } from 'src/app/shared/services/popup.service';

@Component({
  selector: 'app-baufinanzierung',
  templateUrl: './baufinanzierung.component.html',
  styleUrls: ['../../summary.component.scss', './baufinanzierung.component.scss']
})
export class BaufinanzierungComponent extends BaseComponent implements OnInit {
  baufinanzierung: Zahlungsinformation[];
  baufinanzierungsecond: Zahlungsinformation[];
  constructor(
    appDataService: ApplicationDataService,
    navBarService: NavButtonBarService,
    headerService: HeaderService,
    popupService: PopupService,
    translateService: TranslateService,
    translateListService: TranslateListService,
    dateService: DateService
  ) {
    super(appDataService, navBarService, headerService, popupService, translateService, translateListService, dateService, true);
  }

  ngOnInit() {
    super.ngOnInit();
    this.baufinanzierung = this.haushaltsrechnung?.ausgaben.baufinanzierung.concat(this.haushaltsrechnung?.ausgaben?.baufinanzierungAndereBank);
    this.baufinanzierungsecond = this.haushaltsrechnungSecond?.ausgaben.baufinanzierung.concat(this.haushaltsrechnungSecond?.ausgaben?.baufinanzierungAndereBank);
  }
}
