<div class="toggle-container row">
    <div class="col-sm-12 col-lg-12">
        <h4 *ngIf="grundstuecke.length > 0">{{'summary.financing-check.first-borrower.prefix' | translate}}</h4>
        <div *ngFor="let grundstueck of grundstuecke; index as i">
            <hr *ngIf="i !== 0">
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            {{'summary.personal-data.other-expenditure-and-assets.undeveloped-plots.adress.prefix' | translate}}
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            {{grundstueck.strasse}} {{grundstueck.hausnummer}} <br />
                            {{grundstueck.plz}} {{grundstueck.ort}}
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6 col-lg-6" style="margin-top: initial">
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            {{'summary.personal-data.other-expenditure-and-assets.undeveloped-plots.worth.prefix' | translate}}
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            {{grundstueck.wert | currency: 'EUR'}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <h4 *ngIf="grundstueckesecond?.length > 0">{{'summary.financing-check.second-borrower.prefix' | translate}}</h4>
        <div *ngFor="let grundstueck of grundstueckesecond; index as i">
            <hr *ngIf="i !== 0">
            <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-6">
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            {{'summary.personal-data.other-expenditure-and-assets.undeveloped-plots.adress.prefix' | translate}}
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            {{grundstueck.strasse}} {{grundstueck.hausnummer}} <br />
                            {{grundstueck.plz}} {{grundstueck.ort}}
                        </div>
                    </div>
                </div>

                <div class="col-sm-12 col-md-6 col-lg-6" style="margin-top: initial">
                    <div class="row">
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            {{'summary.personal-data.other-expenditure-and-assets.undeveloped-plots.worth.prefix' | translate}}
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg-6">
                            {{grundstueck.wert | currency: 'EUR'}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>