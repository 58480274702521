import { Directive } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { DBValidatorsUtil } from '@db/db-core';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';

import * as routes from '../routing-path';
import { Antrag } from '../shared/model/antrag';
import { ApplicationData, ProdMainTyp } from '../shared/model/application-data';
import { Darlehensnehmer } from '../shared/model/darlehensnehmer';
import { Grundbuch } from '../shared/model/grundbuch';
import { Haushaltsrechnung } from '../shared/model/haushaltsrechnung';
import { ObjektWert } from '../shared/model/objekt-wert';
import { Personendaten } from '../shared/model/personendaten';
import { Produkt } from '../shared/model/produkt';
import { ProduktParameter, ProdukTyp, VerwendungsZweck } from '../shared/model/produkt-parameter';
import { RentenInformation } from '../shared/model/renten-information';
import { ApplicationDataService } from '../shared/services/application-data.service';
import { DateService } from '../shared/services/date.service';
import { HeaderService, Tab } from '../shared/services/header.service';
import { Mandant, MandantService } from '../shared/services/mandant.service';
import { NavButtonBarService } from '../shared/services/nav-button-bar.service';
import { PopupService } from '../shared/services/popup.service';
import { TranslateListService } from './translate-list.service';

@Directive()
export abstract class BaseComponent{
  public formValidation: FormGroup;

  private validationPopupHeader = 'alert.all.attention.title';
  protected okValidationButton = {
    title: 'alert.all.button.ok',
    click: () => this.popupService.closeAndResetPopup()
  };

  private formSubmitSubscription: Subscription;
  private navBarClickSubscription: Subscription;
  protected DATE_FORMAT = 'DD.MM.YYYY';
  PROD_PAGE_HEADER_TITLE = 'start-produkt.headline';

  currentTab: Tab;
  currentText: string;
  currentTitle: string;

  public datePickerConfig = {
    enableTime: false,
    dateFormat: 'd.m.Y'
  };
  public toggleOpenArray: boolean[];
  public selectedAccordion: string;
  private currPathSubscription: Subscription;

  constructor(
    protected appDataService: ApplicationDataService,
    protected navBarService: NavButtonBarService,
    protected headerService: HeaderService,
    protected popupService: PopupService,
    protected translateService: TranslateService,
    protected translateListService: TranslateListService,
    protected dateService: DateService,
    private isNoRouterComponent?: boolean
  ) 
  {}

  // ################ life cycle methods ##################//
  ngOnInit() {
    this.initAppData();
    if (!this.isNoRouterComponent) {
      this.headerService.currentTab.subscribe(activeTab => (this.currentTab = activeTab));
      this.headerService.currentText.subscribe(currentText => (this.currentText = currentText));
      this.headerService.currentTitle.subscribe(currentTitle => (this.currentTitle = currentTitle));
      console.log('ngOnInit formSubmitSubscription');

      this.formSubmitSubscription = this.navBarService.onFormSubmit.subscribe(() => this.onBeforeFormSubmit());
      this.navBarClickSubscription = this.navBarService.onSave.subscribe(() => this.onSave());
      this.currPathSubscription = this.navBarService.currentPathObs.subscribe(currentPath => {
        this.defineHeaderBannerText(currentPath);
        this.defineHeaderTab(currentPath);
      });
    }
    if (document.getElementById('content')) {
      document.getElementById('content').scrollTop = 0;
    }
  }

  ngOnDestroy() {
    if (!this.isNoRouterComponent) {
      console.log('ngOnDestroy formSubmitSubscription');
      this.formSubmitSubscription.unsubscribe();
      this.navBarClickSubscription.unsubscribe();
      this.currPathSubscription.unsubscribe();
    }
  }

  // ################  getter & setter  ##################//
  protected isMandantBhw(mandantService: MandantService): boolean {
    return mandantService.getMandant() === Mandant.BHW || this.verwendungsZweck === VerwendungsZweck.Modernization;
  }

  get verwendungsZweck(): VerwendungsZweck {
    return this.applicationData.antrag.produkt.produktParameter.verwendungszweck;
  }
  set verwendungsZweck(verwendungsZweck: VerwendungsZweck) {
    this.applicationData.antrag.produkt.produktParameter.verwendungszweck = verwendungsZweck;
  }

  get produktTyp(): ProdukTyp {
    return this.applicationData.antrag.produkt.produktParameter.produkttyp;
  }

  get applicationData() {
    return this.appDataService.applicationData;
  }
  set applicationData(appData: ApplicationData) {
    this.appDataService.applicationData = appData;
  }

  get produkt(): Produkt {
    return this.applicationData.antrag.produkt;
  }
  set produkt(produkt: Produkt) {
    this.applicationData.antrag.produkt = produkt;
  }

  get produktParameter(): ProduktParameter {
    return this.applicationData.antrag.produkt.produktParameter;
  }

  set produktParameter(produktParameter: ProduktParameter) {
    this.applicationData.antrag.produkt.produktParameter = produktParameter;
  }

  get antrag(): Antrag {
    return this.applicationData.antrag;
  }
  set antrag(antrag: Antrag) {
    this.applicationData.antrag = antrag;
  }

  get objektWert(): ObjektWert {
    return this.applicationData.antrag.objektWert;
  }
  set objektWert(objektWert: ObjektWert) {
    this.applicationData.antrag.objektWert = objektWert;
  }

  get grundbuch(): Grundbuch {
    return this.applicationData.antrag.objektWert.grundbuch;
  }

  set grundbuch(grundbuch: Grundbuch) {
    this.applicationData.antrag.objektWert.grundbuch = grundbuch;
  }

  // for hauptdarlehensnehmer
  get personendaten(): Personendaten {
    return this.applicationData.antrag.hauptdarlehensnehmer.personendaten;
  }
  set personendaten(personendaten: Personendaten) {
    this.applicationData.antrag.hauptdarlehensnehmer.personendaten = personendaten;
  }

  set rentenInformation(rentenInformation: RentenInformation) {
    this.applicationData.antrag.hauptdarlehensnehmer.rentenInformation = rentenInformation;
  }
  get rentenInformation(): RentenInformation {
    return this.applicationData.antrag.hauptdarlehensnehmer.rentenInformation;
  }

  get haushaltsrechnung(): Haushaltsrechnung {
    return this.applicationData.antrag.hauptdarlehensnehmer.haushaltsrechnung;
  }
  set haushaltsrechnung(haushaltsrechnung: Haushaltsrechnung) {
    this.applicationData.antrag.hauptdarlehensnehmer.haushaltsrechnung = haushaltsrechnung;
  }

  // for nebendarlehensnehmer
  get personendatenSecond(): Personendaten | undefined {
    return this.applicationData.antrag?.nebendarlehensnehmer?.personendaten;
  }
  set personendatenSecond(personendaten: Personendaten) {
    this.applicationData.antrag.nebendarlehensnehmer.personendaten = personendaten;
  }

  get rentenInformationSecond(): RentenInformation | undefined {
    return this.applicationData.antrag?.nebendarlehensnehmer?.rentenInformation;
  }
  set rentenInformationSecond(rentenInformation: RentenInformation) {
    this.applicationData.antrag.nebendarlehensnehmer.rentenInformation = rentenInformation;
  }

  get haushaltsrechnungSecond(): Haushaltsrechnung {
    return this.applicationData.antrag?.nebendarlehensnehmer?.haushaltsrechnung;
  }
  set haushaltsrechnungSecond(haushaltsrechnung: Haushaltsrechnung) {
    this.applicationData.antrag.nebendarlehensnehmer.haushaltsrechnung = haushaltsrechnung;
  }

  public clearNebensdarlehensnehmer(nebendarlehensnehmer) {
    if(nebendarlehensnehmer == 1){
       delete this.antrag.nebendarlehensnehmer;
    }
    else{
      this.antrag.nebendarlehensnehmer = new Darlehensnehmer();
    }
    
  }
  get status() {
    return this.appDataService.antragStatus;
  }

  get correspondence(): { anrede: string; titel: string; nachname: string } {
    return {
      anrede: this.personendaten.anrede ? this.toCamelCase(this.personendaten.anrede + ' ') : '',
      titel: this.personendaten.titel ? this.personendaten.titel + ' ' : '',
      nachname: this.personendaten.nachname ? this.personendaten.nachname : ''
    };
  }

  get correspondenceSecond(): { anrede: string; titel: string; nachname: string } {
    return {
      anrede: this.personendatenSecond.anrede ? this.toCamelCase(this.personendatenSecond.anrede + ' ') : '',
      titel: this.personendatenSecond.titel ? this.personendatenSecond.titel + ' ' : '',
      nachname: this.personendatenSecond.nachname ? this.personendatenSecond.nachname : ''
    };
  }

  get correspondenceTwoPersons(): { anredeFirst: string; titelFirst: string; nachnameFirst: string; anredeSecond: string; titelSecond: string; nachnameSecond: string } {
    return {
      anredeFirst: this.personendaten.anrede ? this.toCamelCase(this.personendaten.anrede + ' ') : '',
      titelFirst: this.personendaten.titel ? this.personendaten.titel + ' ' : '',
      nachnameFirst: this.personendaten.nachname ? this.personendaten.nachname : '',
      anredeSecond: this.personendatenSecond.anrede ? this.toCamelCase(this.personendatenSecond.anrede + ' ') : '',
      titelSecond: this.personendatenSecond.titel ? this.personendatenSecond.titel + ' ' : '',
      nachnameSecond: this.personendatenSecond.nachname ? this.personendatenSecond.nachname : ''
    };
  }

  get fullName(): string {
    return (this.personendaten.titel ? this.personendaten.titel + ' ' : '') + this.personendaten.vorname + ' ' + this.personendaten.nachname;
  }

  get fullNameSecond(): string {
    return (this.personendatenSecond.titel ? this.personendatenSecond.titel + ' ' : '') + this.personendatenSecond.vorname + ' ' + this.personendatenSecond.nachname;
  }

  // ################ protected methods to be used ##################//

  protected isVerwendungZweckOptionDisable(enumOptionValue): boolean {
    switch (enumOptionValue) {
      case VerwendungsZweck.Modernization: {
        return this.applicationData.prodmainTyp && this.applicationData.prodmainTyp !== ProdMainTyp.Modernization;
      }
      case VerwendungsZweck.InternRescheduling:
      case VerwendungsZweck.Rescheduling: {
        return this.applicationData.prodmainTyp && this.applicationData.prodmainTyp !== ProdMainTyp.Rescheduling;
      }
      case VerwendungsZweck.Expansion:
      case VerwendungsZweck.NewBuild: {
        return this.applicationData.prodmainTyp && this.applicationData.prodmainTyp !== ProdMainTyp.PurchaseNewBuild;
      }
      case VerwendungsZweck.KaufAltbau:
      case VerwendungsZweck.PurchasePlot:
      case VerwendungsZweck.Purchase: {
        return this.applicationData.prodmainTyp && this.applicationData.prodmainTyp !== ProdMainTyp.PurchaseNewBuild;
      }
    }
  }

  protected getVerwendungsZweckOptionValue(modelValue: VerwendungsZweck): VerwendungsZweck {
    if (modelValue) {
      switch (modelValue) {
        case VerwendungsZweck.Modernization: {
          return VerwendungsZweck.Modernization;
        }
        case VerwendungsZweck.InternRescheduling:
        case VerwendungsZweck.Rescheduling: {
          return VerwendungsZweck.Rescheduling;
        }
        case VerwendungsZweck.Expansion:
        case VerwendungsZweck.NewBuild: {
          return VerwendungsZweck.NewBuild;
        }
        case VerwendungsZweck.KaufAltbau:
        case VerwendungsZweck.PurchasePlot:
        case VerwendungsZweck.Purchase: {
          return VerwendungsZweck.Purchase;
        }
      }
    }
  }

  public toCamelCase(str: string) {
    return str
      ? str.toLowerCase().replace(/(?:(^.)|(\s+.))/g, match => {
          return match.charAt(match.length - 1).toUpperCase();
        })
      : '';
  }
  protected translateErrors(errors: any) {
    Object.keys(errors).forEach(key => {
      this.translateListService.translateList(errors[key], 'message');
    });
  }

  protected getDateYearAsNumber(date: Date): number {
    return date ? +moment(date).format('YYYY') : null;
  }

  protected getDateFromYear(year: number): Date {
    return year ? moment(year + '', 'YYYY').toDate() : null;
  }

  protected convertToDBCore(value: any): any {
    if (value === undefined || value === null) {
      return null;
    }
    if (typeof value === 'boolean') {
      return !value ? 'false' : 'true';
    }
    if (typeof value === 'number') {
      return '' + value;
    }
    if (typeof value === 'object' && value.constructor.name.toLowerCase() === 'date') {
      return this.dateService.getDateUIFromModel(value);
    }
    return value;
  }

  protected convertFromDBCore(value: any, type: string): any {
    if (value === undefined || value === null) {
      return null;
    }
    if (type === 'boolean') {
      return this.primitiveToBoolean(value);
    }
    if (type === 'number') {
      return +value;
    }
    if (type === 'date') {
      return this.dateService.getDateModelFromDateUI(value);
    }
    return value;
  }

  private primitiveToBoolean(value: string | number | boolean | null | undefined): boolean {
    if (value === 'true') {
      return true;
    }

    if (value === 'false') {
      return false;
    }

    return undefined;
  }

  public toggleClickAccordion(index: number, value: string) {
    this.toggleOpenArray.fill(false);
    if (this.selectedAccordion !== value) {
      this.selectedAccordion = value;
      if (!this.formValidation.invalid) {
        this.toggleOpenArray[index] = true;
      } else {
        this.toggleOpenArray[index] = false;
      }
    } else {
      this.selectedAccordion = undefined;
      this.toggleOpenArray[index] = false;
    }
  }
  public checkToggleClass(value: string) {
    if (this.formValidation.invalid) {
      if (!this.isValid(value)) {
        return 'enable-error';
      } else if (!this.selectedAccordion || this.selectedAccordion !== value) {
        return 'disable-toggling';
      }
    }
    return '';
  }

  protected isValid(value: string): boolean {
    let isformGroupValid = true;
    (this.formValidation.controls[value] as FormArray).controls.forEach((formControl: FormGroup) => {
      Object.keys(formControl.controls).forEach(key => {
        if (formControl.controls[key].errors) {
          isformGroupValid = false;
        }
      });
    });
    return isformGroupValid;
  }

  protected getProduktLabel(isBhw: boolean): string {
    let productLabel: string;
    if (!this.produktTyp) {
      productLabel = '';
    }
    switch (this.produktTyp) {
      case ProdukTyp.ConstantExpress:
        this.translateService.get('product-typ.constant-express').subscribe((res: string) => {
          productLabel = res;
        });
        break;
      case ProdukTyp.FH:
        this.translateService.get('product-typ.fh').subscribe((res: string) => {
          productLabel = res;
        });
        break;
      case ProdukTyp.TH:
        if (isBhw) {
          this.translateService.get('product-typ.th-bhw').subscribe((res: string) => {
            productLabel = res;
          });
        } else {
          this.translateService.get('product-typ.th-db').subscribe((res: string) => {
            productLabel = res;
          });
        }
        break;
      case ProdukTyp.ForwardFH:
        this.translateService.get('product-typ.forward-fh').subscribe((res: string) => {
          productLabel = res;
        });
        break;
      case ProdukTyp.ForwardTH:
        if (isBhw) {
          this.translateService.get('product-typ.forward-th-bhw').subscribe((res: string) => {
            productLabel = res;
          });
        } else {
          this.translateService.get('product-typ.forward-th-db').subscribe((res: string) => {
            productLabel = res;
          });
        }
        break;
    }
    return productLabel;
  }

  protected scrollToElementId(id: string) {
    setTimeout(() => {
      document.getElementById(id).scrollIntoView();
    }, 10);
  }
  // ################ protected methods to be overridden ##################//

  /**
   * This method can be overridden
   * if an action is needed after Save nav button click
   */
  protected onSave(): void {}

  /**
   *  This method can be overridden
   * if some more app data have to be initialized.
   */
  protected initAppData(): void {}

  /**
   * This method can be overridden if any custom validation is needed
   */
  protected validationFormIntern(): boolean {
    if (this.formValidation && this.formValidation.invalid) {
      DBValidatorsUtil.markFormAsDirtyAndTouched(this.formValidation);
      return false;
    }
    return true;
  }
  getDatePickerConfig(minDate: Date, maxDate: Date) {
    // limiting date picker will only be done in desktop application and not in native moblie calendars
    // Cause: native mobile calendars do not support disabling and inputting specific dates
    return {
      minDate: this.isMobileDevice() ? undefined : minDate,
      maxDate: this.isMobileDevice() ? undefined : maxDate
    };
  }
  /**
   * This method can be overridden if any custom validation is needed
   */
  protected validationPopupIntern(): boolean {
    return true;
  }

  protected openValidationPopupWindow(contentTextKey: string) {
    this.popupService.title = this.validationPopupHeader;
    this.popupService.contentText = contentTextKey;
    this.popupService.centerButton = this.okValidationButton;
    this.popupService.error = true;
    this.popupService.openPopup();
  }

  protected onBeforeFormSubmit() {
    this.onFormSubmit();
  }
  // ################ private methods ##################//

  private onFormSubmit() {
    const isValid: boolean = this.validationFormIntern() && this.validationPopupIntern();
    console.log('Is form valid=%s', isValid);
    if (!isValid && this.formValidation) {
      Object.keys(this.formValidation.controls).forEach(controlKey => {
        console.log('control %s errors=', controlKey, this.formValidation.controls[controlKey].errors);
        console.log('control %s values=', controlKey, this.formValidation.controls[controlKey].value);
      });
    } else {
      this.successValidation();
    }
    this.navBarService.setValid(isValid);
    if (isValid) {
      this.navBarService.setRedirectUrl();
      this.navBarService.navigate();
    }
  }

  protected successValidation() {
    console.log('Application data=', this.antrag);
  }

  private defineHeaderTab(currentPath: string) {
    // product pages
    if (!this.currentTab || (this.currentTab !== Tab.Product && this.isProductPage(currentPath))) {
      this.headerService.changeTab(Tab.Product);
    }
    // personal, property & bank pages
    if (!this.currentTab || (this.currentTab !== Tab.PersonalData && this.isPersonalDataPage(currentPath))) {
      this.headerService.changeTab(Tab.PersonalData);
    }
    // summary page
    if (!this.currentTab || (this.currentTab !== Tab.Summary && this.isSummaryPage(currentPath))) {
      this.headerService.changeTab(Tab.Summary);
    }
    // completion poges
    if (!this.currentTab || (this.currentTab !== Tab.Completion && this.isCompletionPage(currentPath))) {
      this.headerService.changeTab(Tab.Completion);
    }
  }

  private defineHeaderBannerText(currentPath: string) {
    if (!this.currentText || (this.currentText !== this.PROD_PAGE_HEADER_TITLE && this.isProductPage(currentPath))) {
      this.headerService.changeText(this.PROD_PAGE_HEADER_TITLE);
    }
  }

  private isProductPage(currentPath: string) {
    return currentPath === routes.SLASH + routes.PROD_MAIN_PATH || currentPath.lastIndexOf('/loan', 0) === 0;
  }

  private isPersonalDataPage(currentPath: string) {
    return currentPath.lastIndexOf('/personal') === 0 || currentPath.lastIndexOf('/property') === 0 || currentPath === routes.SLASH + routes.BANK_ACCOUNT_PATH;
  }

  private isSummaryPage(currentPath: string) {
    return currentPath === routes.SLASH + routes.SUMMARY_PATH;
  }

  private isCompletionPage(currentPath: string) {
    return currentPath === routes.SLASH + routes.APPLICATION_SELECTION_PATH || currentPath === routes.SLASH + routes.CONCLUSION_PATH;
  }

  private isMobileDevice() {
    return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  }
}
